import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/content/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { Contact } from "../components/pages/Contact/Contact";

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      email
      twitter
      instagram
      emailText
      emailAfterText
      _rawContactInfo
    }
  }
`;

const ContactPage = ({ data, errors, location }) => {
  if (errors) {
    return (
      <Layout location={location}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout location={location}>
      <SEO title="Contact" keywords={site.keywords} />

      <Contact
        email={site.email}
        twitter={site.twitter}
        instagram={site.instagram}
        emailText={site.emailText}
        emailAfterText={site.emailAfterText}
        body={site._rawContactInfo}
      />
    </Layout>
  );
};

export default ContactPage;
