import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import { Typography } from "../Typography/Typography";

import styles from "./Title.module.scss";

const Title = ({
  backLink,
  backText = "Back",
  children,
  large,
  titleSize = "heading",
  titleTag = "h1"
}) => {
  const cls = classNames(
    styles.title,
    {
      [styles.withBack]: !!backLink
    },
    {
      [styles.large]: large
    }
  );

  return (
    <>
      {backLink && (
        <Link to={backLink} className="link">
          ← {backText}
        </Link>
      )}
      <Typography tagName={titleTag} type={titleSize} className={cls}>
        {children}
      </Typography>
    </>
  );
};

export { Title };
