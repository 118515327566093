import React from "react";
import { Link } from "gatsby";
import { Typography } from "../../common/Typography/Typography";
import styles from "./Contact.module.scss";
import { Title } from "../../common/Title/Title";
import Obfuscate from "react-obfuscate";
import Body from "../../content/portable-text";

const Contact = ({ email, twitter, instagram, emailText, emailAfterText, body }) => {
  return (
    <section className={styles.intro}>
      <div className={styles.introInner}>
        <Typography type="heading">
          <Obfuscate email={email} target="_blank">
            {emailText || email}
          </Obfuscate>
          {emailAfterText}
        </Typography>
        <Typography tagName="section" type="heading" className={styles.contactMain}>
          <Body blocks={body || []} />
        </Typography>
        {twitter && (
          <Typography tagName="section" type="heading" className={styles.twitter}>
            <Link to={twitter} target="_blank">
              Twitter
            </Link>
          </Typography>
        )}
        {instagram && (
          <Typography tagName="section" type="heading" className={styles.instagram}>
            <Link to={instagram} target="_blank">
              Instagram
            </Link>
          </Typography>
        )}
      </div>
    </section>
  );
};

export { Contact };
